import { Outlet, useParams, Navigate, useLocation } from 'react-router-dom';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import { useCallback, useEffect, useState } from 'react';
import { authenticatedApi } from 'src/Api/api';
import FullScreenLoader from '@components/FullScreenLoader/FullScreenLoader';
import { MissionType } from 'src/Stores/CurrentMissionTypes/MissionType';

let firstLoad = true;

export default function CurrentMissionSelfCareContainer() {

  const location = useLocation();
  const [hasError, setHasError] = useState(false);
  const { caseId, missionId } = useParams<{caseId: string; missionId: string;}>();
  const { mission, setAll } = useCurrentMission();

  const fetchFileAndMission = useCallback(async (): Promise<{ mission: any; } | null> => {

    try {
      const missionResponse = await authenticatedApi.get(`/cases/${caseId}/missions/${missionId}`);
      const mission = missionResponse.data.mission as MissionType;

      setAll({
        file: null,
        mission,
        missionContext: missionResponse.data.context,
        fileCaseContractForm: null,
      });

      return {
        mission,
      };
    } catch (error) {
      setHasError(true);
      return null;
    }
  }, [caseId, missionId, setAll]);

  useEffect(() => {
    fetchFileAndMission();
  }, [fetchFileAndMission]);

  if (hasError) {
    return null;
  }

  if (!mission) {
    return <FullScreenLoader />;
  }

  const missionRegex = /\/self-care\/cases\/[A-Z0-9]+\/missions\/[0-9]+\/?$/;
  const isMissionUrl = location.pathname.match(missionRegex);
  if (isMissionUrl && firstLoad && mission.alreadyUsedSelfCareToken) {
    console.log('Redirecting to mission');
    firstLoad = false;
    return <Navigate to="./mission" />;
  }

  return (
    <Outlet />
  )
}