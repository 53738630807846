import { AllianzLogoSmall, IconLogout, PhoneChat, Warranty } from '@assets/icons/icons';
import styles from './Header.module.scss';
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import BackBtn from './BackBtn';
import version from "src/version.json";
import { useNavigate } from 'react-router-dom';
import PhoneChatModal from './PhoneChatModal/PhoneChatModal';
import { ConfirmProps, useConfirmAlert } from 'src/Stores/ConfirmAlertStore';
import { useAuthStore } from 'src/Stores/AuthStore';


export default function HeaderSelfCare({
  title,
  displayBackButton = false,
  displayWarrantiesButton = true,

  onBackButtonClick: onBackButtonClickOverride,
  backBtnConfirm,
  displayMissionProgressBar = false,
  forceProgressBarPercent,
  children,
  containerProps: { className, ...containerProps } = {},
}: {
  title?: string;
  displayBackButton?: boolean;
  displayWarrantiesButton?: boolean;
  onBackButtonClick?: () => any;
  backBtnConfirm?: Omit<ConfirmProps, "onConfirm" | "onCancel" | "layout">;
  displayMissionProgressBar?: boolean;
  forceProgressBarPercent?: number;
  children?: React.ReactNode;
  containerProps?: Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;
}) {

  const {
    logout,
    isAuthenticatedSelfCareKratos
  } = useAuthStore();
  const {
    confirm
  } = useConfirmAlert();
  const [displayModalPhoneChat, setDisplayModalPhoneChat] = useState(false);
  const navigate = useNavigate();
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const { missionContext, missionContextGoBack, canGoBack } = useCurrentMission();

  const onBackButtonClick = useCallback(() => {
    if (onBackButtonClickOverride) {
      onBackButtonClickOverride();
    } else if (canGoBack()) {
      if (backBtnConfirm) {
        confirm({
          ...backBtnConfirm,
          onConfirm: () => {
            missionContextGoBack();
          },
          onCancel: () => void 0,
          layout: "minimal",
        });
      } else {
        missionContextGoBack();
      }
    }
  }, [onBackButtonClickOverride, canGoBack, backBtnConfirm, confirm, missionContextGoBack]);

  const missionProgressBarPercent = useMemo(() => {
    if (!missionContext) {
      return 0;
    }
    if (forceProgressBarPercent) {
      return forceProgressBarPercent;
    }
    const totalSteps = missionContext.progressBar.steps.length;
    const currentStepIndex = missionContext.progressBar.currentIndex;

    return ((currentStepIndex) + 1) / totalSteps * 100;

  }, [missionContext, forceProgressBarPercent]);

  // Adapt placeholder height to the content height
  useLayoutEffect(() => {
    const content = contentRef.current;
    if (!content) {
      return;
    }
    const height = content.clientHeight;

    setHeight(height);
  }, []);

  return (
    <>
      {displayModalPhoneChat && <PhoneChatModal onRequestClose={() => setDisplayModalPhoneChat(false)} />}

      <div className={[styles.containerSelfcare, styles.placeholder].join(" ")} style={{ height }} />

      <div ref={contentRef} className={[styles.containerSelfcare, styles.containerFixed, className].join(" ")} {...containerProps}>
        <div className={styles.contentContainer}>

          <div className={styles.logoContainer}>
            <AllianzLogoSmall />
            <div className={styles.versionNumber}>{version.selfcare} (selfcare)</div>
          </div>


          <div className={styles.actions}>

            {displayWarrantiesButton && (
            <div className={styles.warranty} onClick={() => {
              navigate("../warranties")
            }}>
              <Warranty />
            </div>
            )}
            {/* <div className={styles.langSelector}>
              <World /><div className={styles.langSelectorText}>FR</div>
            </div>

            <div className={styles.question}>
              <Question />
            </div> */}

            <div className={styles.phoneChat} onClick={() => {
              setDisplayModalPhoneChat(true);
            }}>
              <PhoneChat />
            </div>

            {isAuthenticatedSelfCareKratos && (
            <div className={styles.logoutContainer} onClick={() => {
              logout();
            }}>
              <IconLogout />
            </div>
            )}

          </div>
        </div>

        {displayMissionProgressBar && (
          <div className={styles.missionProgressBar}>
            <div className={styles.missionProgressBarInner} style={{ width: `${missionProgressBarPercent}%` }} />
          </div>
        )}

        {(title || displayBackButton) && (
          <div className={styles.title}>
            {displayBackButton ?
            (<BackBtn
              onClick={onBackButtonClick}
            />) :
            (<div className={styles.leftPlaceholder} />)
            }
            <div>
              {title}
            </div>
            <div className={styles.rightPlaceholder} />
          </div>
        )}

        {children && (
          <div className={styles.childrenContainer}>
            {children}
          </div>
        )}
      </div>
    </>
  )
}