import styles from './ResultsVRentalSelection.module.scss';
import layoutStyles from '../../CurrentMission.module.scss';
import Button from '@components/Button/Button';

import { useCallback, useMemo, useState } from 'react';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import { MapWrapper } from '@components/GoogleMap/Map';
import CurrentMissionVerticalSteps from '../../CurrentMissionVerticalSteps';
import { RentalSolutionPoint, getRentalId } from './VRentalMap/utils';
import CarChoiceItem from '@components/CarChoiceItem/CarChoiceItem';
import VRentalMap from './VRentalMap/VRentalMap';
import useFilterVRentals from 'src/Pages/_Components/Filter/useFilterVRentals';
import FilterButton from '@components/FilterButton/FilterButton';
import FilterModal from 'src/Pages/_Components/Filter/FilterModal';
import TextEllipsisWithPopup from '@components/TextEllipsisWithPopup/TextEllipsisWithPopup';
import { useTranslation } from 'react-i18next';
import HeaderV2 from 'src/layout/Header/V2/HeaderV2';

export default function ResultsVRentalSelection() {
  const { t } = useTranslation();
  const { callAction } = useCurrentMission();
  const [rentalIdsActivated, setRentalIdsActivated] = useState<string[]>([]);
  const [displayOnlyTop3Activated, setDisplayOnlyTop3] = useState(true);
  const { mission, missionContext, isCurrentStepReadOnly } = useCurrentMission();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState<number>(0);

  const filters = useMemo(() => {
    return {
      hasRemainingCosts: {
        label: t("filter_result_label"),
        defaultValue: missionContext?.steps?.solutions?.filters?.noRemainingCosts?.value !== undefined ? !missionContext?.steps?.solutions?.filters?.noRemainingCosts?.value : true,
      },
      ...(missionContext?.steps?.searchVrental?.filters || {}),
    };
  }, [missionContext?.steps?.searchVrental?.filters, missionContext?.steps?.solutions?.filters?.noRemainingCosts, t]);

  const {
    idsFiltered,
    countNbFiltersApplied,
    onClickButtonFilter,
    modalProps,
    resetFilters,
  } = useFilterVRentals(missionContext?.steps?.searchVrental?.rentals || [], filters);

  const onPointSelected = useCallback((point: RentalSolutionPoint | null) => {
    if (point) {
      setRentalIdsActivated(point.solutionsIds);
      setSelected(0);
    } else {
      setRentalIdsActivated([]);
    }
  }, []);

  const rentals = useMemo(() => {
    return (missionContext?.steps.searchVrental?.rentals || []).filter((rental) => {
      return idsFiltered[rental.id];
    });
  }, [idsFiltered, missionContext?.steps.searchVrental?.rentals])

  if (!mission || !missionContext || !missionContext?.steps?.searchVrental) {
    return null;
  }

  const agencies = missionContext.steps.searchVrental.agencies.filter((agency) => {
    return rentals.some((rental) => rental.agencyID === agency.id);
  });

  if (!agencies || !rentals) {
    return null;
  }

  const isFilteredByPoi = rentalIdsActivated.length !== 0;
  const displayOnlyTop3 = displayOnlyTop3Activated && !isFilteredByPoi;

  let rentalsToDisplay = rentals;
  if (displayOnlyTop3) {
    rentalsToDisplay = rentals.slice(0, 3);
  }
  rentalsToDisplay = rentalsToDisplay.filter((rental) => {
    if (rentalIdsActivated.length === 0) {
      return true;
    }
    return rentalIdsActivated.includes(getRentalId(rental));
  });

  const selectedRental = rentalsToDisplay[selected];
  const remainingEnvelopeAmount = mission.guaranteeDetails?.isEnvelope ? (mission.guaranteeDetails.envelope.availableAmount.amount - (selectedRental?.price.amount || 0))/100 : null;

  const getRemainingEnvelope = () => {
    if (remainingEnvelopeAmount === null) {
      return null;
    }
    if (!mission.guaranteeDetails?.envelope?.availableAmount?.currency?.symbol) {
      return null;
    }
    const currencySymbol = mission.guaranteeDetails.envelope.availableAmount.currency.symbol;
    const absAmount = Math.abs(remainingEnvelopeAmount);

    if (remainingEnvelopeAmount >= 0) {
      return t("remaining_envelope_positive", { amount: remainingEnvelopeAmount, currency: currencySymbol });
    } else {
      return t("remaining_envelope_negative", { amount: absAmount, currency: currencySymbol });
    }
  };

  const onSubmit = async () => {
    if (isCurrentStepReadOnly()) {
      return;
    }
    if (selected === null) {
      alert(t("alert_select_car"));
      return false;
    }
    setLoading(true);
    try {
      const selectedSolution = rentalsToDisplay[selected];
      const action = selectedSolution.chooseAction;
      if (!action) {
        throw new Error("Missing chooseAction");
      }

      await callAction(action);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }

  }

  const daysCount = missionContext.steps.searchVrental.daysCount;
  let title = `${rentals.length} véhicule${rentals.length > 1 ? "s" : ""} disponible${rentals.length > 1 ? "s" : ""} pour ${daysCount} jour${daysCount > 1 ? "s" : ""}`;
  if (rentals.length === 0) {
    title = "Aucun véhicule disponible pour les critères demandés";
  }
  if (isFilteredByPoi) {
    const firstRental = rentals.find((rental) => rentalIdsActivated.includes(getRentalId(rental)));
    if (firstRental) {
      title = `${rentalIdsActivated.length} véhicule${rentalIdsActivated.length > 1 ? "s" : ""} disponible${rentalIdsActivated.length > 1 ? "s" : ""} chez ${firstRental.agencyLabel}`;
    }
  }

  return (
    <MapWrapper>

      <FilterModal
        {...modalProps}
      />

      <PageContainer
        containerProps={{
          className: [layoutStyles.container].join(" "),
        }}
      >
        <HeaderV2 />

        <div className={layoutStyles.content}>
          <CurrentMissionVerticalSteps displayBackButton />

          <div className={styles.mapContainer}>
            <div className={styles.sectionHeader}>
              <TextEllipsisWithPopup
                containerProps={{
                  className: styles.sectionHeaderTitle,
                }}
              >
                {`${agencies.length} agence${agencies.length > 1 ? "s" : ""}`}
              </TextEllipsisWithPopup>
            </div>

            <VRentalMap rentals={rentals} onPointSelected={onPointSelected} />
          </div>

          <div className={styles.resultsContainer}>
            <div className={styles.sectionHeader}>
              <TextEllipsisWithPopup
                containerProps={{
                  className: styles.sectionHeaderTitle,
                }}
              >{title}</TextEllipsisWithPopup>
              <FilterButton
                onClick={onClickButtonFilter}
                countNbFiltersApplied={countNbFiltersApplied}
              />
            </div>

            <div className={styles.results}>

              {rentalsToDisplay.length === 0 && (
                <>
                  <div className={styles.noResults}>{t("no_results_message")}</div>
                  <Button
                    type="secondary"
                    label={t("reset_filters")}
                    onClick={() => {
                      resetFilters();
                    }}
                  />
                </>
              )}

              {rentalsToDisplay.map((rental, i) => (

                <CarChoiceItem
                  key={i}
                  readOnly={isCurrentStepReadOnly()}
                  title={rental.agencyLabel}
                  distance={`${rental.agencyDistanceKM} km`}
                  carName={rental.label}
                  carImg={rental.imgURL}
                  agencyImg={rental.renterImgURL}
                  tags={rental.tags}
                  detailedTags={rental.detailedTags}
                  carConditions={rental.conditions}
                  co2Emission={rental.kgCO2e ? {
                          value: `${rental.kgCO2e} kgCO2e`,
                    level: rental.ecologyRating
                  } : undefined}
                  price={rental.price}
                  priceLeftToPay={rental.remainingCosts ? (rental.remainingCosts.formatted + " " + rental.remainingCosts.currency.symbol + " à charge") : undefined}

                  carOptions={Array.isArray(rental.descriptions) ? rental.descriptions : [rental.descriptions || ""]}

                  selected={selected === i}
                  onSelect={() => setSelected(i)}
                  containerProps={{
                    className: styles.carChoiceItem,
                  }}
                  optionsIncluded={rental.included_options ?? [
                    "Conducteur supplémentaire",
                    "Annulation gratuite jusqu'à 48 heures à l'avance",
                    "Protection en cas de vol avec une franchise de 1 500 €",
                    "Couverture partielle en cas de collision avec une franchise de 1 500 €",
                    "Kilométrage"
                  ]}
                  optionsAvailable={rental.chargeable_options ?? [
                    "Conducteur supplémentaire",
                    "Annulation gratuite jusqu'à 48 heures à l'avance",
                    "Siège bébé",
                    "Siège réhausseur pour enfant",
                    "Siège enfant"
                  ]}
                />

              ))}

            </div>

            {displayOnlyTop3 && rentals.length > 3 && (
              <div
                onClick={() => {
                  setDisplayOnlyTop3(false);
                }}
                className={styles.displayMoreButton}
              >
                {t("display_more_offers")}
              </div>
            )}
          </div>
        </div>

        <div className={layoutStyles.footer}>
          <div />
          <div className={styles.footerBtnContainer}>
            {mission.guaranteeDetails?.isEnvelope && remainingEnvelopeAmount &&(

              <div className={`${styles.envelope} ${remainingEnvelopeAmount < 0 ? styles.isNegative : ''}`}>
                {getRemainingEnvelope()}
              </div>
            )}
            <Button
              containerProps={{
                disabled: selected === null || loading || isCurrentStepReadOnly(),
              }}
              label={loading ? t("loading") : t("validate")}
              onClick={onSubmit}
            />
          </div>
        </div>



      </PageContainer>
    </MapWrapper>

  )
}