import { useEffect, useMemo, useState } from "react";


function getDatePlus23Hours(date: Date) {
  const newDate = new Date(date);
  newDate.setHours(date.getHours() + 23);
  return newDate;
}

function isSameDate(date1?: Date | null, date2?: Date | null) {
  if (!date1 && !date2) {
    return true;
  }
  if (!date1 || !date2) {
    return false;
  }
  return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
}



type FormDateType = {
  date: Date;
  hour: number;
  minutes: number;
  dateReturn: Date | null;
  hourReturn: number | null;
  minutesReturn: number | null;
};

export default function useFormDates(searchForm: {
  datetime?: string;
  returnDatetime?: string;
  defaultVrentalDays?: number;
}) {

  const currentDatePlus1Hour = useMemo(() => {
    //const date = new Date("2024-06-10T19:01:00");
    const date = new Date();
    date.setMinutes(date.getMinutes() + 30 - (date.getMinutes() % 15));
    return date;
  }, []);
  const currentDatePlus23HoursReturn = useMemo(() => {
    return getDatePlus23Hours(currentDatePlus1Hour);
  }, [currentDatePlus1Hour]);

  let defaultReturnDate = currentDatePlus23HoursReturn;
  if (searchForm.defaultVrentalDays && searchForm.defaultVrentalDays > 1) {
    defaultReturnDate = new Date(currentDatePlus1Hour.getTime() + (searchForm.defaultVrentalDays * 1000 * 60 * 60 * 24));
  }

  const [formDate, setFormDate] = useState<FormDateType>({
    date: searchForm?.datetime ? new Date(searchForm?.datetime) : currentDatePlus1Hour,
    hour: searchForm?.datetime ? new Date(searchForm?.datetime).getHours() : currentDatePlus1Hour.getHours(),
    minutes: searchForm?.datetime ? new Date(searchForm?.datetime).getMinutes() : currentDatePlus1Hour.getMinutes(),
    dateReturn: searchForm?.returnDatetime ? new Date(searchForm?.returnDatetime) : defaultReturnDate,
    hourReturn: searchForm?.returnDatetime ? new Date(searchForm?.returnDatetime).getHours() : defaultReturnDate.getHours(),
    minutesReturn: searchForm?.returnDatetime ? new Date(searchForm?.returnDatetime).getMinutes() : defaultReturnDate.getMinutes(),
  });

  const onChangeNbDays = (nbDays: number) => {
    if (nbDays < 1) {
      return;
    }
    setFormDate((prev) => ({
      ...prev,
      dateReturn: new Date(prev.date.getTime() + (nbDays * 1000 * 60 * 60 * 24)),
      hourReturn: prev.hour,
      minutesReturn: prev.minutes,
    }));
  }

  const minDateReturn = useMemo(() => {
    const date = new Date(formDate.date);
    date.setHours(formDate.hour);
    date.setMinutes(formDate.minutes);
    return getDatePlus23Hours(date);
  }, [formDate.date, formDate.hour, formDate.minutes]);

  useEffect(() => {

    if (formDate.dateReturn && (
      formDate.dateReturn < minDateReturn
      || (formDate.hourReturn !== null && formDate.hourReturn < minDateReturn.getHours())
      || (formDate.minutesReturn !== null && formDate.minutesReturn < minDateReturn.getMinutes())
    )) {

      setFormDate((prev) => ({
        ...prev,
        dateReturn: minDateReturn,
        hourReturn: minDateReturn.getHours(),
        minutesReturn: minDateReturn.getMinutes(),
      }));
    }
  }, [formDate.dateReturn, formDate.hourReturn, formDate.minutesReturn, minDateReturn]);

  useEffect(() => {
    if (formDate.date < currentDatePlus1Hour) {
      setFormDate((prev) => ({
        ...prev,
        date: currentDatePlus1Hour,
        hour: currentDatePlus1Hour.getHours(),
        minutes: currentDatePlus1Hour.getMinutes(),
      }));
    }
  }, [currentDatePlus1Hour, formDate.date]);

  const nbDays = (formDate.date && formDate.dateReturn)
    ? Math.ceil((formDate.dateReturn.getTime() - formDate.date.getTime()) / (1000 * 60 * 60 * 24))
    : 0;

  return {
    formDate,
    setFormDate,

    nbDays,
    onChangeNbDays,

    minDate: currentDatePlus1Hour,
    minHour: isSameDate(formDate.date, currentDatePlus1Hour) ? currentDatePlus1Hour.getHours() : 0,
    minMinutes: (isSameDate(formDate.date, currentDatePlus1Hour) && formDate.date?.getHours() === currentDatePlus1Hour.getHours()) ? currentDatePlus1Hour.getMinutes() : 0,

    minDateReturn,
    minHourReturn: isSameDate(formDate.dateReturn, minDateReturn) ? minDateReturn.getHours() : 0,
    minMinutesReturn: (isSameDate(formDate.dateReturn, minDateReturn) && formDate.dateReturn?.getHours() === minDateReturn.getHours()) ? minDateReturn.getMinutes() : 0,
  };
}
