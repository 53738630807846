import React from 'react';
import Select, { SelectProps } from '@components/Select/Select';

export default function HourPicker({
  disabled,
  hour,
  minutes,
  minHour = 0,
  minMinutes = 0,
  onChange,
  containerProps: { className, ...containerProps } = {},
  layout,
  testid,
}: {
  disabled?: boolean;
  hour: number | null;
  minutes: number | null;
  minHour?: number;
  minMinutes?: number;
  onChange?: (hourMinutes: { hour: number | null; minutes: number | null }) => any;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  layout?: SelectProps["layout"];
  testid?: string;
}) {

  const options = Array.from({ length: 24 }, (_, i) => i).filter((i) => i >= minHour).map((i) => {
    return [0, 15, 30, 45].filter((m) => i !== minHour || m >= minMinutes).map((m) => `${i < 10 ? '0'+i : i}:${m < 10 ? '0'+m : m}`);
  }).flat();

  return (
    <Select
      testid={testid}
      layout={layout}
      disabled={disabled}
      options={options}
      selectedIndex={(hour !== null && minutes !== null) ? options.findIndex((option) => option === `${hour < 10 ? '0'+hour : hour}:${minutes < 10 ? '0'+minutes : minutes}`) : null}
      onChangeIndex={(index) => {
        onChange && onChange({
          hour: index !== null ? parseInt(options[index].split(':')[0]) : null,
          minutes: index !== null ? parseInt(options[index].split(':')[1]) : null,
        });
      }}
      containerProps={{
        className,
        ...containerProps,
      }}
    />
  )
}