
import styles from './ItinerarySolutions.module.scss';

import { useCallback, useMemo, useState } from 'react';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import { getPointsFromSolutions } from './ItineraryMap/utils';
import useFilterSolutionsV2 from 'src/Pages/_Components/Filter/FilterV3/FilterSolutions/useFilterSolutions';
import { PriceType } from 'src/models/Price';
import { isSelfCare } from 'src/Stores/AppContext';
import { TransportTypes } from 'src/Stores/CurrentMissionTypes/Types';
import { useTranslation } from 'react-i18next';


export type TypeSolutionsByTransportType = {
  [key in TransportTypes]: {
    countSolutions: number;
    minimumDuration: number;
  }
};

export default function useItinerarySolutions() {

  const { t } = useTranslation();

  const [mainTransportTypeFilter, setMainTransportTypeFilter] = useState<TransportTypes | null>(null);
  const [solutionIdsActivated, setSolutionIdsActivated] = useState<string[]>([]);
  const [displayOnlyTop3Activated, setDisplayOnlyTop3] = useState(true);
  const { mission, missionContext, callAction, isCurrentStepReadOnly } = useCurrentMission();
  const [loading, setLoading] = useState(false);
  const [extendActionLoading, setExtendActionLoading] = useState(false);
  const [selected, setSelected] = useState<number>(0);
  const [selectedTaxiIndexes, setSelectedTaxiIndexes] = useState<number[]>([]);

  const stepIsReadOnly = isCurrentStepReadOnly();

  const alerts = missionContext?.steps?.solutions?.alerts || [];

  const useFiltersValue = useFilterSolutionsV2(
    missionContext?.steps.solutions,
    {
      disabledFilters: isSelfCare() ? ["mainTransportType"] : [],
    }
  );

  const {
    idsFiltered,
    resetFilters,
    countNbFiltersApplied,
    onClickButtonFilter,
    modalProps,
    appliedFilterValues,
  } = useFiltersValue;

  const solutionsByTransportTypes = useMemo(() => {
    const allSolutions = missionContext?.steps.solutions?.solutions || [];
    const allSolutionsFiltered = allSolutions.filter((solution) => idsFiltered[solution.id]);
    const byTransportTypes: TypeSolutionsByTransportType = {
      taxi: {
        countSolutions: 0,
        minimumDuration: Infinity,
      },
      train: {
        countSolutions: 0,
        minimumDuration: Infinity,
      },
      vrental: {
        countSolutions: 0,
        minimumDuration: Infinity,
      },
      walk: {
        countSolutions: 0,
        minimumDuration: Infinity,
      },
      arrival: {
        countSolutions: 0,
        minimumDuration: Infinity,
      }
    };

    allSolutionsFiltered.forEach(solution => {
      const mainTransportType = solution.mainTransportType.id;
      byTransportTypes[mainTransportType].countSolutions++;
      if (solution.durationInMinutes < byTransportTypes[mainTransportType].minimumDuration) {
        byTransportTypes[mainTransportType].minimumDuration = solution.durationInMinutes;
      }
    });

    return byTransportTypes;
  }, [missionContext?.steps.solutions?.solutions, idsFiltered]);

  const solutions = useMemo(() => {
    const allSolutions = missionContext?.steps.solutions?.solutions || [];
    return allSolutions.filter((solution) => {
      if (!idsFiltered[solution.id]) {
        return false;
      }

      if (mainTransportTypeFilter !== null && solution.mainTransportType.id !== mainTransportTypeFilter) {
        return false;
      }

      return true;
    });
  }, [missionContext?.steps.solutions?.solutions, idsFiltered, mainTransportTypeFilter]);


  const onSelectItinerary = useCallback(({
    selectedAllSectionsIndex,
    onSelectParams,
  }: {
    selected: boolean;
    selectedAllSectionsIndex: number[];
    onSelectParams?: {
      index: number;
    };
  }) => {
    if (stepIsReadOnly) {
      return;
    }
    setSelected(onSelectParams?.index || 0);
    setSelectedTaxiIndexes(selectedAllSectionsIndex);
  }, [stepIsReadOnly]);

  if (!mission || !missionContext) {
    return null;
  }

  const points = getPointsFromSolutions(solutions);

  const isFilteredByPoi = solutionIdsActivated.length !== 0;
  const displayOnlyTop3 = displayOnlyTop3Activated && !isFilteredByPoi;
  let solutionsToDisplay = solutions;
  if (displayOnlyTop3) {
    solutionsToDisplay = solutions.slice(0, 3);
  }
  solutionsToDisplay = solutionsToDisplay.filter((solution) => {
    if (solutionIdsActivated.length === 0) {
      return true;
    }
    return solutionIdsActivated.includes(solution.id);
  });

  const selectedSolution = solutionsToDisplay[selected];

  const getRemainingCosts = (): PriceType | null => {
    const dataByEnabledSections = selectedSolution?.dataByEnabledSections || {};
    const keyData = selectedTaxiIndexes.join(",");

    return dataByEnabledSections[keyData]?.remainingCosts || null;
  }

  const getRemainingEnvelopeAmount = (): PriceType | null => {
    const dataByEnabledSections = selectedSolution?.dataByEnabledSections || {};
    const keyData = selectedTaxiIndexes.join(",");

    return dataByEnabledSections[keyData]?.remainingEnvelope || null;
  }

  const remainingEnvelopeAmount = getRemainingEnvelopeAmount();
  const remainingCost = getRemainingCosts();

  const getRemainingEnvelopeText = () => {

    if (remainingCost) {
      return `Reste à charge après validation ${remainingCost.formatted}`;
    }

    if (remainingEnvelopeAmount === null) {
      return null;
    }

    return `Enveloppe restante après validation ${remainingEnvelopeAmount.formatted} ${remainingEnvelopeAmount.currency.symbol}`;
  };

  const onSubmit = async (forceSelectedIndex?: number) => {

    const selectedIndex = forceSelectedIndex !== undefined ? forceSelectedIndex : selected;

    if (stepIsReadOnly) {
      return;
    }
    if (selectedIndex === null) {
      alert("Veuillez sélectionner un itinéraire");
      return false;
    }
    setLoading(true);
    try {
      const selectedSolution = solutionsToDisplay[selectedIndex];
      if (!selectedSolution.chooseSolutionAction) {
        throw new Error("Missing chooseSolutionAction");
      }
      await callAction(selectedSolution.chooseSolutionAction, {});
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }

  }

  const countNbSolutions = displayOnlyTop3 ? Math.min(solutions.length, 3) : solutions.length;
  let title = countNbSolutions > 0 ?
    t('top_itinerary_with_count', { countNbSolutions }) :
    t('top_itinerary');
  let titleClass = "";
  if (isFilteredByPoi) {
    const firstSolution = solutions.find((solution) => solutionIdsActivated.includes(solution.id));
    if (firstSolution) {
      title = `Via ${firstSolution.mainOrigin.name}`;
      titleClass = styles.sectionHeaderTitleHighlight;
    }
  }

  const solutionsCount = solutions.reduce((acc, solution) => {
    return acc + solution.itemsCount;
  }, 0);

  return {
    mission,
    extendSearchCallAction: missionContext?.steps.solutions?.actions?.extendSearch ? async () => {
      if (!missionContext?.steps.solutions?.actions?.extendSearch) {
        return;
      }
      setLoading(true);
      setExtendActionLoading(true);
      try {
        await callAction(missionContext.steps.solutions.actions.extendSearch, {});
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
        setExtendActionLoading(false);
      }
    } : undefined,
    alerts,
    callAction,
    title,
    titleClass,
    searchRadius: missionContext?.steps.solutions?.searchRadius,
    solutions,
    solutionsCount,
    points,
    selected,
    loading,
    setLoading,
    extendActionLoading,
    setExtendActionLoading,
    stepIsReadOnly,
    selectedSolution,
    remainingEnvelopeAmount,
    remainingCost,
    isFilteredByPoi,
    idsFiltered,
    getRemainingEnvelopeText,
    onSelectItinerary,
    onSubmit,
    setDisplayOnlyTop3,
    displayOnlyTop3,
    solutionsToDisplay,
    countNbFiltersApplied,
    resetFilters,
    onClickButtonFilter,
    modalProps,
    appliedFilterValues,
    setSolutionIdsActivated,
    mainTransportTypeFilter,
    setMainTransportTypeFilter,
    solutionsByTransportTypes,
  }
}