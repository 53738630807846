import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import styles from './AddMissionPage.module.scss';
import Button from '@components/Button/Button';
import Header from 'src/layout/Header/Header';
import stylesForm from '@components/Form/Form.module.scss';
import Select from '@components/Select/Select';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { authenticatedApi } from 'src/Api/api';
import useContractOptions from 'src/Hooks/useContractOptions';
import { useTranslation } from 'react-i18next';

type FormData = {
  contract: number | null;
  eventOutcome: number | null;
  subcover: number | null;
};

export default function AddMissionPage({
  containerProps: { className, ...containerProps } = {},
}: {
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {
  const { t } = useTranslation();
  const [submitAction, setSubmitAction] = useState<{
    href: string;
    method: string;
  } | null>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { caseId } = useParams();

  const fetchFormOptions = useCallback(async () => {
    const response = await authenticatedApi.request({
      url: `cases/${caseId}/missionForm`,
      method: 'GET',
    });

    return response.data.missionForm;
  }, [caseId]);

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState,
  } = useForm<FormData>();

  const contractId = watch("contract");
  const eventOutcomeId = watch("eventOutcome");

  const  {
    contracts,
    setContracts,
    eventOutcomes,
    subcovers
  } = useContractOptions({
    contractId,
    eventOutcomeId,
  });

  useEffect(() => {
    setValue("eventOutcome", null);
    setValue("subcover", null);
  }, [contractId, setValue]);

  useEffect(() => {
    setValue("subcover", null);
  }, [eventOutcomeId, setValue]);

  useEffect(() => {
    fetchFormOptions().then((data) => {
      setContracts(data.contracts);
      setSubmitAction(data.actions.submit);

      if (data.defaultContractID) {
        setValue("contract", data.defaultContractID);
      }

      if (data.defaultEventID) {
        setTimeout(() => {
          setValue("eventOutcome", data.defaultEventID);
        }, 0);
      }
    });
  }, [fetchFormOptions, setContracts, setValue]);

  const onSubmit = async (data: FormData) => {
    setLoading(true);

    try {
      if (!submitAction) {
        throw new Error("Missing submitAction");
      }

      const response = await authenticatedApi.request({
        url: submitAction.href,
        method: submitAction.method,
        data: {
          contractID: data.contract,
          eventOutcomeID: data.eventOutcome,
          subcoverID: data.subcover,
        },
      });

      if (response.status === 200) {
        navigate(`/cases/${caseId}/missions/${response.data.mission.id}`);
      }

      return false;
    } catch (e) {
      console.log("error", e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContainer
      containerProps={{
        ...containerProps,
        className: [styles.container, className].join(" "),
      }}
    >
      <Header />

      <div className={styles.content}>
        {/* <div style={{ height: 2000 }}></div> */}
        <div className={styles.formContainer}>
          <div className={styles.formTitle}>{t('add_mission_title')}</div>
          <form className={[styles.form, stylesForm.form].join(" ")} onSubmit={handleSubmit(onSubmit)}>
            <div className={`${stylesForm.formRow} ${styles.fileRow}`}>
              <div className={stylesForm.formCol}>
                {t('case_number')} <span className={stylesForm.formValueLeft}>{caseId}</span>
              </div>
              <div className={stylesForm.formCol}>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/");
                    return false;
                  }}
                  className={`${stylesForm.formLink} ${styles.formLink}`}
                >
                  {t('change_case')}
                </a>
              </div>
            </div>

            <div className={`${stylesForm.formRow} ${styles.formRow}`}>
              <Controller
                name="contract"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t('contract_required'),
                  },
                }}
                render={({ field }) => (
                  <Select
                    testid="select-contract"
                    label={t('contract_label')}
                    placeholder={t('contract_placeholder')}
                    optionsV2={contracts.map((contract) => ({
                      label: contract.label,
                      value: contract.id,
                    }))}
                    selectedIndex={contracts.findIndex((contract) => contract.id === field.value)}
                    onChangeIndex={(selectedIndex) => {
                      if (selectedIndex === null) {
                        field.onChange(null);
                        return;
                      }
                      field.onChange(contracts[selectedIndex].id);
                    }}
                    containerProps={{
                      className: "w-full",
                    }}
                  />
                )}
              />
            </div>

            {!!contractId && (
              <div className={`${stylesForm.formRow} ${styles.formRow}`}>
                <Controller
                  name="eventOutcome"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t('event_required'),
                    },
                  }}
                  render={({ field }) => (
                    <Select
                      testid="select-event-outcome"
                      label={t('event_label_cause')}
                      placeholder={t('event_placeholder')}
                      optionsV2={eventOutcomes.map((eventOutcome) => ({
                        label: eventOutcome.label,
                        value: eventOutcome.id,
                      }))}
                      selectedIndex={eventOutcomes.findIndex((eventOutcome) => eventOutcome.id === field.value)}
                      onChangeIndex={(selectedIndex) => {
                        if (selectedIndex === null) {
                          field.onChange(null);
                          return;
                        }
                        field.onChange(eventOutcomes[selectedIndex].id);
                      }}
                      containerProps={{
                        className: "w-full",
                      }}
                    />
                  )}
                />
              </div>
            )}

            {!!eventOutcomeId && (
              <div className={`${stylesForm.formRow} ${styles.formRow}`}>
                <Controller
                  name="subcover"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t('subcover_required'),
                    },
                  }}
                  render={({ field }) => (
                    <Select
                      testid="select-subcover"
                      label={t('subcover_label')}
                      placeholder={t('subcover_placeholder')}
                      optionsV2={subcovers.map((subcover) => ({
                        label: subcover.label,
                        value: subcover.id,
                      }))}
                      selectedIndex={subcovers.findIndex((subcover) => subcover.id === field.value)}
                      onChangeIndex={(selectedIndex) => {
                        if (selectedIndex === null) {
                          field.onChange(null);
                          return;
                        }
                        field.onChange(subcovers[selectedIndex].id);
                      }}
                      containerProps={{
                        className: "w-full",
                      }}
                    />
                  )}
                />
              </div>
            )}

            <Button
              testid="button-submit-create-mission"
              label={loading ? t('submit_loading') : t('submit_label')}
              type="primary"
              containerProps={{
                className: "w-full mt-8",
                disabled: loading || !formState.isValid,
                type: "submit",
              }}
            />
          </form>
        </div>
      </div>
    </PageContainer>
  );
}