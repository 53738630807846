import { useTranslation } from 'react-i18next'; // Import useTranslation hook from react-i18next
import styles from './Home.module.scss';
import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import HeaderSelfCare from 'src/layout/Header/Header.selfcare';
import transport_img from './images/transport.svg';
import Button from '@components/Button/Button';
import { useNavigate } from 'react-router-dom';
import FooterLinks from 'src/layout/SelfCare/Footer/FooterLinks';
import { useAuthStore } from 'src/Stores/AuthStore';
import { useEffect, useState } from 'react';
import { getAuthenticatedApiInstance } from 'src/Api/api';

export default function HomeSelfCare() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const {
    isAuthenticatedSelfCareKratos
  } = useAuthStore();

  const [lastMission, setLastMission] = useState<{
    case: string;
    id: string;
  } | null>(null);


  useEffect(() => {
    if (!isAuthenticatedSelfCareKratos) {
      return;
    }
    getAuthenticatedApiInstance({
      errorHandlerEnabled: false
    }).get("/missions").then((response) => {
      console.log(response.data);
      if (!response.data?.lastMission?.actions?.view?.href) {
        throw new Error("No view action found");
      }

      setLastMission({
        case: response.data.lastMission.caseID,
        id: response.data.lastMission.id,
      });

    }).catch((error) => {
      console.error(error);
      setLastMission(null);
    });
  }, [isAuthenticatedSelfCareKratos]);

  console.log("missions", lastMission);

  return (
    <PageContainer
      containerProps={{
        className: [styles.container, 'selfcare'].join(" "),
      }}
    >

      <HeaderSelfCare
        displayMissionProgressBar
        displayWarrantiesButton={false}
        forceProgressBarPercent={10}
      />

      <div className={styles.content}>

        {isAuthenticatedSelfCareKratos && (
        <div className={styles.contentContainer}>

          <div className={styles.title}>
            {t('welcome_title')}
          </div>

          <p>
            {t('mobility_solutions_text')}
          </p>

          <div className={styles.imgInfoContainer}>
            <img src={transport_img} alt="Illustration" />
          </div>

          <div className={styles.actions}>
            <Button
              label={t('view_reservation_button')}
              onClick={() => {
                if (lastMission?.case && lastMission?.id) {
                  navigate(`cases/${lastMission.case}/missions/${lastMission.id}/mission`);
                }
              }}

              type="primary"
              containerProps={{
                className: styles.actionButton,
                disabled: lastMission === null,
              }}
            />

            <Button
              label={t('book_new_service_button')}
              onClick={() => navigate("./new-mission")}
              type="secondary"
              containerProps={{
                className: styles.actionButton,
              }}
            />

          </div>

        </div>
        )}

        {!isAuthenticatedSelfCareKratos && (
        <div className={styles.contentContainer}>

          <div className={styles.title}>
            {t('welcome_title')}
          </div>

          <p>
            {t('manage_mobility_text')}
          </p>

          <p>
            {t('register_to_manage_mobility_text')}
          </p>

          <div className={styles.imgInfoContainer}>
            <img src={transport_img} alt="Illustration" />
          </div>

          <div className={styles.actions}>
            <Button
              label={t('login_button')}
              onClick={() => navigate("login")}
              type="primary"
              containerProps={{
                className: styles.actionButton,
              }}
            />

            <Button
              onClick={() => navigate("register")}
              type="secondary"
              label={t('register_button')}
              containerProps={{
                className: styles.actionButton,
              }}
            />

          </div>

        </div>
        )}

        <div className={styles.footerContainer}>
          <FooterLinks
            containerProps={{
              className: styles.footer,
            }}
          />
        </div>

      </div>



    </PageContainer>
  )
}