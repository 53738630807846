import { Calendar, Close } from '@assets/icons/icons';
import styles from './DateRangePicker.module.scss';
import React from 'react';
import ReactDatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

export default function DateRangePicker({
  disabled,
  readOnly,
  label,
  errorMsg,
  dateRange,
  minDate,
  maxDate,
  onChange,
  containerProps: { className, ...containerProps } = {},
  datePickerContainerProps: { className: datePickerContainerClassName, ...datePickerContainerProps } = {},
  datePickerProps,
  testid,
}: {
  disabled?: boolean;
  readOnly?: boolean;
  label?: string;
  errorMsg?: string;
  dateRange: [Date | null, Date | null];
  minDate?: Date | null;
  maxDate?: Date | null;
  onChange?: (dateRange: [Date | null, Date | null]) => any;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  datePickerContainerProps?: React.HTMLAttributes<HTMLDivElement>;
  datePickerProps?: Omit<ReactDatePicker["props"], 'onChange'>;
  testid?: string;
}) {

  const displayedValue = dateRange.map((date) => date?.toLocaleDateString() || "DD/MM/YYYY").join(" - ");

  return (
    <div
      data-testid={testid}
      {...containerProps}
      className={[styles.container, disabled ? styles.disabled : "", className].join(" ")}
    >

      {(label || errorMsg) && (
        <div className={styles.labelContainer}>
          {label && <div className={styles.label}>{label}</div>}
          {errorMsg && <div className={styles.errorMsg}>{errorMsg}</div>}
        </div>
      )}

      <div {...datePickerContainerProps} className={`${datePickerContainerClassName}`}>
        <ReactDatePicker
          {...datePickerProps}

          selectsRange={true}
          onChange={(date) => {
            onChange && onChange(date)
          }}
          startDate={dateRange[0]}
          endDate={dateRange[1]}
          allowSameDay
          monthsShown={2}
          dropdownMode='select'

          disabled={disabled || readOnly}
          minDate={minDate}
          maxDate={maxDate}
          customInput={(
            <div className={styles.input}>
              <div className={styles.value}>{displayedValue}</div>
              {!!dateRange[0] && !!dateRange[1] && (
                <div className={styles.clear} onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onChange && onChange([null, null])
                }}>
                  <Close width={20} height={20} />
                </div>
              )}
              <Calendar />
            </div>
          )}
        />
      </div>

    </div>
  )
}