import { Navigate, useLocation } from "react-router-dom";
import { useAuthWithKratos } from "src/Hooks/useAuthWithKratos";

export default function RequireAuth({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const { loading, isLogged } = useAuthWithKratos();

  // if (!auth.isAuthenticated()) {
  //   console.log("REDIRECT TO LOGIN");
  //   const returnTo = (new URL(window.location.href)).searchParams.get("return_to") || window.location.pathname
  //   return <Navigate to={"/login?return_to="+encodeURIComponent(returnTo)} state={{ from: location }} replace />;
  // }

  if (loading) {
    return "Loading...";
  }

  if (!isLogged) {
    console.log("REDIRECT TO LOGIN");
    console.trace();
    const returnTo = (new URL(window.location.href)).searchParams.get("return_to") || window.location.pathname
    return <Navigate to={"/login?return_to="+encodeURIComponent(returnTo)} state={{ from: location }} replace />;
  }

  return children;
}