import { useCurrentMission } from "src/Stores/CurrentMission";
import AddReceiptSelfCare from "./AddReceipt";
import { useEffect, useState } from "react";
import { ScreenType } from "./ScreensTypes";
import AddReceiptStepReceiptSelfCare from "./Steps/Receipt/Receipt";
import { usePrevious } from "@uidotdev/usehooks";
import AddBeneficiaryInformationsStepReceiptSelfCare from "./Steps/BeneficiaryInformations/BeneficiaryInformations";
import ConfirmationStepReceiptSelfCare from "./Steps/Confirmation/Confirmation";
import NotCoveredStepReceiptSelfCare from "./Steps/NotCovered/NotCovered";

export default function AddReceiptFlow() {
  const {
    missionContext
  } = useCurrentMission();

  console.log("MISSION CONTEXT", missionContext, "MISSION CONTEXT");

  const refundFlow = missionContext?.refundFlow;

  const [currentScreen, setCurrentScreen] = useState<ScreenType>(refundFlow?.screen || "start");
  const previousScreen = usePrevious(currentScreen);

  useEffect(() => {
    setCurrentScreen(refundFlow?.screen || "start");
  }, [refundFlow?.screen]);

  if (!refundFlow) {
    return null;
  }

  console.log("currentScreen", currentScreen, "previousScreen", previousScreen);
  const navigate = (screen: ScreenType) => {
    setCurrentScreen(screen);
  }

  switch (currentScreen) {
    case 'start':
      return (
        <AddReceiptSelfCare
          navigate={navigate}
        />
      );
    case 'receipt':
      return (
        <AddReceiptStepReceiptSelfCare
          refundFlow={refundFlow}
          goBack={() => {
            setCurrentScreen("start");
          }}
          navigate={navigate}
        />
      )
    case 'bankingInformation':
      if ((refundFlow?.coveredAmount?.amount || 0) === 0) {
        return (
          <NotCoveredStepReceiptSelfCare />
        );
      }
      return (
        <AddBeneficiaryInformationsStepReceiptSelfCare
          refundFlow={refundFlow}
          goBack={() => {
            setCurrentScreen("receipt");
          }}
          navigate={navigate}
        />
      );
    case 'recap':
      if (refundFlow?.coveredAmount?.amount === 0) {
        return (
          <NotCoveredStepReceiptSelfCare />
        );
      }
      return (
        <ConfirmationStepReceiptSelfCare />
      );
    default:
      return null;
      break;
  }

}