import { setDefaultOptions } from 'date-fns';
import i18n from './i18n';
import enUS from 'date-fns/locale/en-US';
import fr from 'date-fns/locale/fr';
import de from 'date-fns/locale/de';

export const locales: {[key: string]: any} = {
  en: enUS,
  fr,
  de,
};

// Function to set or update the locale in date-fns based on i18next's current language
const updateDateFnsLocale = () => {
  const currentLang = i18n.language.split('-')[0]; // Get the current language code in the same format
  console.log("currentLang", currentLang);
  const dateFnsLocale = locales[currentLang] || fr; // Fallback to fr if no match
  setDefaultOptions({ locale: dateFnsLocale });
};

updateDateFnsLocale();

i18n.on('languageChanged', updateDateFnsLocale);