import { useTranslation } from 'react-i18next';
import styles from './ResultsVRentalSelection.module.scss';

import { useMemo, useState } from 'react';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import { getRentalId } from '../VRentalMap/utils';
import useFilterVRentals from 'src/Pages/_Components/Filter/useFilterVRentals';
import FilterButton from '@components/FilterButton/FilterButton';
import FilterModal from 'src/Pages/_Components/Filter/FilterModal';
import TextEllipsisWithPopup from '@components/TextEllipsisWithPopup/TextEllipsisWithPopup';
import HeaderSelfCare from 'src/layout/Header/Header.selfcare';
import CarChoiceItemSelfCare from '@components/CarChoiceItem/SelfCare/CarChoiceItem';
import { format } from 'date-fns';
import VRentalSelectedPage from './VRentalSelectedPage/VRentalSelectedPage';
import Button from '@components/Button/Button';

export default function ResultsVRentalSelection() {
  const { t } = useTranslation();

  const [rentalIdsActivated] = useState<string[]>([]);
  const [displayOnlyTop3Activated, setDisplayOnlyTop3] = useState(true);
  const { mission, missionContext } = useCurrentMission();
  const [selected, setSelected] = useState<number | null>(null);

  const stepIsReadOnly = false;

  const filters = useMemo(() => {
    return {
      hasRemainingCosts: {
        label: t('filterLabel'),
      },
      ...(missionContext?.steps?.searchVrental?.filters || {}),
    }
  }, [missionContext?.steps?.searchVrental?.filters, t]);

  const agencyVrentals = (missionContext?.steps?.searchVrental?.rentals || []).filter(rental => rental.agencyID == missionContext?.steps?.searchVrental?.selectedAgencyID)

  const {
    idsFiltered,
    countNbFiltersApplied,
    onClickButtonFilter,
    modalProps,
    resetFilters,
  } = useFilterVRentals(agencyVrentals, filters);

  const rentals = useMemo(() => {
    return agencyVrentals.filter((rental) => {
      return idsFiltered[rental.id];
    });
  }, [idsFiltered, agencyVrentals])

  if (!mission || !missionContext || !missionContext?.steps?.searchVrental) {
    return null;
  }

  const agencies = missionContext.steps.searchVrental.agencies.filter((agency) => {
    return rentals.some((rental) => rental.agencyID === agency.id);
  });

  if (!agencies || !rentals) {
    return null;
  }

  const isFilteredByPoi = rentalIdsActivated.length !== 0;
  const displayOnlyTop3 = displayOnlyTop3Activated && !isFilteredByPoi;

  let rentalsToDisplay = rentals;
  if (displayOnlyTop3) {
    rentalsToDisplay = rentals.slice(0, 3);
  }
  rentalsToDisplay = rentalsToDisplay.filter((rental) => {
    if (rentalIdsActivated.length === 0) {
      return true;
    }
    return rentalIdsActivated.includes(getRentalId(rental));
  });

  const selectedRental = selected !== null ? rentalsToDisplay[selected] : null;

  const daysCount = missionContext.steps.searchVrental.daysCount;

  let title = `Location ${daysCount} jour${daysCount > 1 ? 's' : ''}`;

  if (missionContext.steps.search?.formData?.datetime && missionContext.steps.search?.formData?.returnDatetime) {
    const dateStart = new Date(missionContext.steps.search?.formData?.datetime);
    const dateEnd = new Date(missionContext.steps.search?.formData?.returnDatetime);
    title += ` du ${format(dateStart, 'dd/MM HH:mm')} au ${format(dateEnd, 'dd/MM HH:mm')}`;
  } else if (missionContext.steps.search?.formData?.datetime) {
    const dateStart = new Date(missionContext.steps.search?.formData?.datetime);
    title += ` à partir du ${format(dateStart, 'dd/MM HH:mm')}`;
  }

  if (selectedRental) {
    return (
      <VRentalSelectedPage
        rental={selectedRental}
        onBack={() => setSelected(null)}

        optionsIncluded={selectedRental.included_options ?? []}
        optionsAvailable={selectedRental.chargeable_options ?? []}
      />
    )
  }

  return (
    <>

      <FilterModal
        {...modalProps}
      />

      <PageContainer>

        <HeaderSelfCare
          displayMissionProgressBar
          title={t('headerTitle')}
          displayBackButton
        />

        <div className={styles.content}>


          <div className={styles.sectionHeader}>
            <TextEllipsisWithPopup
              containerProps={{
                className: styles.sectionHeaderTitle,
              }}
            >{title}</TextEllipsisWithPopup>
            <FilterButton
              layout="outlined"
              onClick={onClickButtonFilter}
              countNbFiltersApplied={countNbFiltersApplied}
            />
          </div>

          <div className={styles.results}>

            {rentalsToDisplay.length === 0 && (
              <>
                <div className={styles.noResults}>
                  {t('no_results_explanation')}
                </div>
                <Button
                  type="secondary"
                  label={t('resetFiltersButton')}
                  onClick={() => {
                    resetFilters();
                  }}
                />
              </>
            )}

            {rentalsToDisplay.map((rental, i) => (

              <CarChoiceItemSelfCare
                key={i}
                readOnly={stepIsReadOnly}
                distance={`${rental.agencyDistanceKM} km`}
                carName={rental.selfCareLabel || ""}
                carImg={rental.imgURL}
                agencyLabel={rental.agencyLabel}
                agencyImg={rental.renterImgURL}
                tags={rental.tags}
                carConditions={rental.conditions}
                co2Emission={rental.kgCO2e ? {
                  value: `${rental.kgCO2e} kgCO2e`,
                  level: rental.ecologyRating
                } : undefined}
                remainingCosts={rental.remainingCosts}
                carOptions={Array.isArray(rental.descriptions) ? rental.descriptions : [rental.descriptions || ""]}

                selected={selected === i}
                onSelect={() => setSelected(i)}
                containerProps={{
                  className: styles.carChoiceItem,
                }}
              />

            ))}

          </div>

          {displayOnlyTop3 && rentals.length > 3 && (
            <div onClick={() => {
              setDisplayOnlyTop3(false);
            }} className={styles.displayMoreButton}>{t('viewAllOffersButton')}</div>
          )}


        </div>

      </PageContainer>
    </>

  )
}