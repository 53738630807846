import { useTranslation } from 'react-i18next';
import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import styles from './ItineraryRecapPage.module.scss';
import layoutStyles from '../../../CurrentMission.selfcare.module.scss';
import Button from '@components/Button/Button';
import { Oval } from 'react-loader-spinner'
import VRentalSection from './Components/VRentalSection/VRentalSection';
import useConfirmationPageController from '../useConfirmationPageController.ts';
import HeaderSelfCare from 'src/layout/Header/Header.selfcare.tsx';
import RecapDateTime from '../Components/RecapDateTime/RecapDateTime.tsx';
import RecapItinerary from './Components/RecapItinerary/RecapItinerary.tsx';
import FeedbackWithStars from '../Components/FeedbackWithStars/FeedbackWithStars.tsx';
import { CheckValid, IconCheckNotValid } from '@assets/icons/icons.tsx';
import StringRichText from '@components/StringRichText/StringRichText.tsx';
// import ButtonDownloadReceipt from './Components/ButtonDownloadReceipt/ButtonDownloadReceipt.tsx';
import { useEffect, useState } from 'react';
import { useCurrentMission } from 'src/Stores/CurrentMission.ts';
import { useAuthStoreLicensePlate } from 'src/Stores/AuthStoreLicensePlate.ts';
import { useAuthStore } from 'src/Stores/AuthStore.ts';
import ButtonDownloadReceipt from './Components/ButtonDownloadReceipt/ButtonDownloadReceipt.tsx';
import useMissionContextRefreshInterval from 'src/Hooks/useMissionContextRefreshInterval.ts';
import { useSearchParams } from 'react-router-dom';
import FullScreenLoader from '@components/FullScreenLoader/FullScreenLoader.tsx';

export default function ItineraryConfirmationPage({
  containerProps: { className, ...containerProps } = {},
}: {
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  const { t } = useTranslation();
  const searchParams = useSearchParams()
  const autoSave = searchParams[0].get("save") === "true";
  const [isAutoSaved, setIsAutoSaved] = useState(false);
  const [loadingReview, setLoadingReview] = useState(false);
  const { isAuthenticatedSelfCareLicencePlace } = useAuthStoreLicensePlate();
  const { isAuthenticatedSelfCareKratos } = useAuthStore();
  const { callAction, missionContext: missionContextOpt } = useCurrentMission();
  const confirmationPageController = useConfirmationPageController();
  const paymentProcessing = confirmationPageController?.missionContext.steps?.recap?.paymentStatus?.id === 'processing';
  useMissionContextRefreshInterval({
    refreshInterval: 2000,
    enabled: paymentProcessing
  });

  useEffect(() => {
    if (!missionContextOpt?.steps?.confirmation?.linkToSelfCareAccount) {
      return;
    }
    if (!isAuthenticatedSelfCareLicencePlace || !isAuthenticatedSelfCareKratos) {
      setIsAutoSaved(true);
      return;
    }
    if (autoSave && confirmationPageController && missionContextOpt.steps.confirmation.linkToSelfCareAccount) {
      callAction(missionContextOpt.steps.confirmation.linkToSelfCareAccount, {}, {
        prefixIfSelfCare: false,
      }).then(() => {
        setIsAutoSaved(true);
      });
    }
  }, [autoSave, callAction, confirmationPageController, isAuthenticatedSelfCareKratos, isAuthenticatedSelfCareLicencePlace, missionContextOpt?.steps?.confirmation?.linkToSelfCareAccount]);

  if ((autoSave && !isAutoSaved) || !confirmationPageController) {
    return <FullScreenLoader />
  }

  const {
    recap,
    missionContext,
    navigate,
    score,
    setReviewScoreAction,
    recapDate,
    isTaxiOnly,
    validUntilDate,
    optionIsToday,
    stepAction,
    loading,
  } = confirmationPageController;

  const paymentReceiptNav = recap.paymentReceiptNav;

  return (
    <PageContainer
      containerProps={{
        ...containerProps,
        className: [layoutStyles.container, styles.container, className].join(" "),
      }}
    >

      {loading && (
        <div className={styles.loadingFullScreen}>
          <Oval
            height={40}
            width={40}
            color="white"
            secondaryColor="white"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}

      <HeaderSelfCare
        title={t('confirmation')}
        displayBackButton={false}
        displayMissionProgressBar
      />

      <div data-testid="step-confirmation" className={[styles.content].join(" ")}>

        <div className={styles.sectionHeader}>
          <div className={styles.confirmTitleContainer}>
            {missionContext.steps.confirmation?.status?.isValid === true || missionContext.steps.confirmation?.status?.isValid === undefined ?
              <CheckValid height={24} width={24} />:
              <IconCheckNotValid height={24} width={24} />
            }
            <div className={styles.confirmTitle}>{missionContext.steps.confirmation?.status?.title}</div>

          </div>
          {missionContext.steps?.confirmation?.message &&(
            <StringRichText containerProps={{ className: styles.infosEmail }}>
              {missionContext.steps.confirmation.message}
            </StringRichText>
            )}
        </div>

        <RecapDateTime
          isTaxiOnly={isTaxiOnly}
          recapDate={recapDate}
          validUntil={recap.validUntil}
          validUntilDate={validUntilDate}
          optionIsToday={optionIsToday}
          durationMinutes={recap.durationMinutes}
          displayDuration={false}
          containerProps={{
            className: styles.recapDateTime,
          }}
        />

        {recap.sections && recap.sections.length > 0 && (
        <RecapItinerary
          sections={recap.sections}
          displayPrice={false}
          containerProps={{
            className: styles.recapItinerary,
          }}
        />
        )}

        {recap.vrentalsBySectionID && Object.keys(recap.vrentalsBySectionID).map((sectionID) => (
          <VRentalSection
            key={sectionID}
            rental={recap.vrentalsBySectionID[sectionID]}
            containerProps={{
              className: styles.vRentalSection,
            }}
          />
        ))}

        <div className={styles.recapCostsContainer}>

          <div className={styles.recapRemainingCosts}>
            {recap.remainingCosts === null || recap.remainingCosts?.amount === 0 ? (
              <>
                <span>{t('total_cost_label')}</span>
                <div className={styles.remainingCostsIncluded}>
                  {t('included_in_contract')}
                </div>
              </>
            ) : (
              <>
                <span>{t('amount_paid_label')}</span>
                <span className={styles.remainingCostsNotIncluded}>{`${recap.remainingCosts?.formatted} ${recap.totalPrice?.currency.symbol}`}</span>
              </>
            )}
          </div>

        </div>

        {!!paymentReceiptNav && (
        <div className='mb-4 w-full pt-4'>
          <ButtonDownloadReceipt
            label={paymentReceiptNav.label}
            href={paymentReceiptNav.href}
          />
        </div>
        )}

        {missionContext.steps?.confirmation?.createReviewAction && (
          <FeedbackWithStars
            containerProps={{
              className: styles.feedback,
            }}
            loading={loadingReview}
            selectedStar={score}
            onClickStar={async (star) => {
              if (!missionContext.steps?.confirmation?.createReviewAction) {
                return;
              }
              setLoadingReview(true);
              await setReviewScoreAction(missionContext.steps?.confirmation?.createReviewAction, { score: star });
              setLoadingReview(false);
            }}
          />
        )}

        <div className={styles.recapAction}>

          {missionContext?.steps.confirmation?.callSupportNav && (
            <Button
              label={missionContext?.steps.confirmation?.callSupportNav.label}
              type="secondary"
              containerProps={{
                className: 'w-full mb-4',
                disabled: loading,
              }}
              onClick={() => {
                if (!missionContext?.steps.confirmation?.callSupportNav) {
                  return;
                }
                window.location.href = missionContext.steps.confirmation.callSupportNav.href;
              }}
            />
          )}

          {missionContext?.steps?.confirmation?.linkToSelfCareAccount && (
          <Button
            label={t('save_information_label')}
            type="primary"
            containerProps={{
              className: 'w-full mb-4',
              disabled: loading,
            }}
            onClick={async () => {
              if (!isAuthenticatedSelfCareLicencePlace || !isAuthenticatedSelfCareKratos) {
                navigate("/self-care/login?return_to="+ encodeURIComponent(window.location.pathname + "?save=true"));
                return;
              }
              if (!missionContext?.steps.confirmation?.linkToSelfCareAccount) {
                return;
              }
              await callAction(missionContext.steps.confirmation.linkToSelfCareAccount, {}, {
                prefixIfSelfCare: false,
              });
              navigate("/self-care");
            }}
          />
          )}

          <Button
            label={t('return_home')}
            type="secondary"
            containerProps={{
              className: 'w-full mb-4',
              disabled: loading,
            }}
            onClick={() => {
              if (!isAuthenticatedSelfCareKratos) {
                navigate("../");
                return;
              }
              navigate("/self-care");
            }}
          />

          {missionContext?.steps.confirmation?.cancelAction &&  (
          <Button
            label={t('cancel_service_label')}
            type="secondary"
            containerProps={{
              className: 'w-full',
              disabled: loading,
            }}
            onClick={() => {
              stepAction('cancel');
            }}
          />
          )}
        </div>



      </div>

    </PageContainer>
  )
}