import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import styles from './SearchMissionsResults.module.scss';
import { useTranslation } from 'react-i18next';
import { MissionSearchResult } from 'src/models/Mission';
import React from 'react';
import Tag from '@components/Tag/Tag';
import { statusLabels } from 'src/helpers/statusLabels';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

function TableTd({
  children,
  isLoading,
  rowSpan,
  className,
  containerClassName,
  testid,
}: {
  children?: React.ReactNode;
  isLoading?: boolean;
  rowSpan?: number;
  className?: string;
  containerClassName?: string;
  testid?: string;
}) {
  return (
    <td rowSpan={rowSpan} className={className} data-testid={testid}>
      <div
        className={containerClassName}
        style={{ visibility: isLoading ? 'hidden': 'visible'}}>{children}</div>
      {isLoading && (<div className={styles.skeletonContainer}>
        <Skeleton
          width={'100%'}
          height={10}
          containerClassName={styles.skeleton}
        />
      </div>)}
    </td>
  )
}

export default function SearchMissionsResults({
  isLoading,
  results = [],
  containerProps: { className, ...containerProps } = {},
}: {
  isLoading?: boolean;
  results?: Array<MissionSearchResult>;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {
  const { t } = useTranslation();

  const resultsGroupedByCaseId = (results || []).reduce((acc, result) => {
    if (!acc[result.caseID]) {
      acc[result.caseID] = [];
    }
    acc[result.caseID].push(result);
    return acc;
  }, {} as Record<string, MissionSearchResult[]>);

  const hasResults = Object.keys(resultsGroupedByCaseId).length > 0;

  return (
    <div className={[styles.container, className].join(" ")} {...containerProps}>
      <table border={0} style={{}}>
        <thead>
          <tr>
            <th>{t('folder_number')}</th>
            <th>{t('mission_number')}</th>
            <th>{t('statusLabel')}</th>
            <th>{t('mainPrestation')}</th>
            <th>{t('missionCreationDateLabel')}</th>
            <th>{t('beneficiary_name')}</th>
            <th>{t('phone_number')}</th>
            <th>{t('license_plate')}</th>
          </tr>
        </thead>

        {(!hasResults) && (
          <tbody className={styles.noResults}>
            <tr>
              {isLoading ? (
                <td colSpan={8}>
                  <Skeleton />
                </td>
              ) : (
                <td colSpan={8}>
                  {t('no_results')}
                </td>
              )}
            </tr>
          </tbody>
        )}

        {hasResults && (
          <tbody>

            {Object.keys(resultsGroupedByCaseId).map((caseId, i) => {
              const results = resultsGroupedByCaseId[caseId];

              // Case id is first column, merged with the next rows
              return (
                <React.Fragment key={i}>
                  <tr className={styles.caseIdLine}>
                    <TableTd isLoading={isLoading} rowSpan={results.length + 1}>
                      <Link
                          to={`/cases/${caseId}`}
                          className={styles.link}
                        >{caseId}</Link>
                    </TableTd>
                  </tr>
                  {results.map((result, j) => {
                    const createdAt = new Date(result.createdAt);
                    const createdAtFormatted = format(createdAt, "dd/MM/yyyy HH:mm");
                    return (
                      <tr key={j} className={j === results.length - 1 ? styles.lastRow : ''}>
                        <TableTd isLoading={isLoading} testid={`mission-link-container-${result.missionID}`}>
                          <Link
                            to={`/cases/${result.caseID}/missions/${result.missionID}`}
                            className={styles.link}
                          >{result.missionID}</Link>
                        </TableTd>
                        <TableTd isLoading={isLoading} className={styles.tagCell} containerClassName={styles.tagContainer}>
                          {result.status ? (
                            <Tag
                              label={result.status.label}
                              color={statusLabels[result.status.id].color}
                              containerProps={{
                                className: styles.tag,
                              }}
                            />
                          ) : null}
                        </TableTd>
                        <TableTd isLoading={isLoading}>
                          {result.mainType?.label}
                        </TableTd>
                        <TableTd isLoading={isLoading}>{createdAtFormatted}</TableTd>
                        <TableTd isLoading={isLoading}>{result.clientFirstname} {result.clientLastname}</TableTd>
                        <TableTd isLoading={isLoading}>{
                          result.clientPhone.split(" ").slice(1).join("")
                        }</TableTd>
                        <TableTd isLoading={isLoading}>{result.licensePlate}</TableTd>
                      </tr>
                    )
                  })}
                </React.Fragment>
              )
            }
            )}
          </tbody>
        )}

      </table>
    </div>
  )
}