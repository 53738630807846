import styles from './NewMission.module.scss';
import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import HeaderSelfCare from 'src/layout/Header/Header.selfcare';
import Button from '@components/Button/Button';
import FooterLinks from 'src/layout/SelfCare/Footer/FooterLinks';
import { useState } from 'react';
import PhoneChatModal from 'src/layout/Header/PhoneChatModal/PhoneChatModal';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function NewMission() {
  const { t } = useTranslation();

  const [displayModalPhoneChat, setDisplayModalPhoneChat] = useState(false);
  const navigate = useNavigate();

  return (
    <PageContainer
      containerProps={{
        className: [styles.container, 'selfcare'].join(" "),
      }}
    >
      {displayModalPhoneChat && <PhoneChatModal onRequestClose={() => setDisplayModalPhoneChat(false)} />}

      <HeaderSelfCare
        displayMissionProgressBar
        displayBackButton
        displayWarrantiesButton={true}
        forceProgressBarPercent={50}
        title={t('title_new_mission')}
        onBackButtonClick={() => navigate(-1)}
      />

      <div className={styles.content}>

        <div className={styles.contentContainer}>

          <p>
            {t('question_mobility_service')}
          </p>
          <p>
            {t('instruction_new_reservation')}
          </p>

          <div className={styles.actions}>

            <Button
              label={t('button_call_advisor')}
              onClick={() => setDisplayModalPhoneChat(true)}
              type="secondary"
              containerProps={{
                className: styles.actionButton,
              }}
            />

          </div>

        </div>


        <div className={styles.footerContainer}>
          <FooterLinks
            containerProps={{
              className: styles.footer,
            }}
          />
        </div>

      </div>



    </PageContainer>
  );
}