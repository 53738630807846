import { useCurrentMission } from "src/Stores/CurrentMission";
import { MissionStepKeys } from "src/Stores/CurrentMissionTypes/Types";

const verticalSteps: {
  allowedStepKeys: MissionStepKeys[];
}[] = [
  {
    allowedStepKeys: ['search'],
  },
  {
    allowedStepKeys: ['solutions', 'searchTrain', 'searchVrental'],
  },
  {
    allowedStepKeys: ['travelerForm'],
  },
  {
    allowedStepKeys: ['recap'],
  },
  {
    allowedStepKeys: ['confirmation'],
  }
];

function getCurrentIndexes(stepKey: MissionStepKeys) {
  const currentVerticalStepIndex = verticalSteps.findIndex((step) => {
    return step.allowedStepKeys.includes(stepKey);
  });

  const currentIndexInStep = verticalSteps[currentVerticalStepIndex].allowedStepKeys.findIndex((step) => {
    return step === stepKey;
  });

  return {
    currentVerticalStepIndex,
    currentIndexInStep,
  };
}

function findPreviousStepKey(currentStepKey: MissionStepKeys, availableStepsKeys: MissionStepKeys[]) {
  const {
    currentVerticalStepIndex,
    currentIndexInStep,
  } = getCurrentIndexes(currentStepKey);

  if (currentVerticalStepIndex === 0) {
    return null;
  }

  if (currentIndexInStep === 0) {
    return findLastAvailableStepKeyInIndex(currentVerticalStepIndex - 1, availableStepsKeys);
  }

  // find previous step in the same vertical step
  for (let i = currentIndexInStep - 1; i >= 0; i--) {
    if (availableStepsKeys.includes(verticalSteps[currentVerticalStepIndex].allowedStepKeys[i])) {
      return verticalSteps[currentVerticalStepIndex].allowedStepKeys[i];
    }
  }

  return null;
}

function findLastAvailableStepKeyInIndex(index: number, availableStepsKeys: MissionStepKeys[]) {
  const allowedStepKeys = verticalSteps[index].allowedStepKeys;
  for (let i = allowedStepKeys.length - 1; i >= 0; i--) {
    if (availableStepsKeys.includes(allowedStepKeys[i])) {
      return allowedStepKeys[i];
    }
  }
}



function test() {
  const trainAvailableSteps: MissionStepKeys[] = ['search', 'solutions', 'searchTrain', 'travelerForm', 'recap', 'confirmation'];
  const taxiAvailableSteps: MissionStepKeys[] = ['search', 'solutions', 'travelerForm', 'recap', 'confirmation'];
  const vrentalAvailableSteps: MissionStepKeys[] = ['search', 'solutions', 'searchVrental', 'travelerForm', 'recap', 'confirmation'];

  const cases: Array<{
    availableStepsKeys: MissionStepKeys[],
    currentStepKey: MissionStepKeys,
    expectedPreviousStepKey: MissionStepKeys | null,
  }> = [
    {
      availableStepsKeys: trainAvailableSteps,
      currentStepKey: 'search',
      expectedPreviousStepKey: null,
    },
    {
      availableStepsKeys: trainAvailableSteps,
      currentStepKey: 'solutions',
      expectedPreviousStepKey: 'search',
    },
    {
      availableStepsKeys: trainAvailableSteps,
      currentStepKey: 'searchTrain',
      expectedPreviousStepKey: 'solutions',
    },

    {
      availableStepsKeys: taxiAvailableSteps,
      currentStepKey: 'search',
      expectedPreviousStepKey: null,
    },
    {
      availableStepsKeys: taxiAvailableSteps,
      currentStepKey: 'solutions',
      expectedPreviousStepKey: 'search',
    },
    {
      availableStepsKeys: taxiAvailableSteps,
      currentStepKey: 'travelerForm',
      expectedPreviousStepKey: 'solutions',
    },

    {
      availableStepsKeys: vrentalAvailableSteps,
      currentStepKey: 'search',
      expectedPreviousStepKey: null,
    },
    {
      availableStepsKeys: vrentalAvailableSteps,
      currentStepKey: 'solutions',
      expectedPreviousStepKey: 'search',
    },
    {
      availableStepsKeys: vrentalAvailableSteps,
      currentStepKey: 'searchVrental',
      expectedPreviousStepKey: 'solutions',
    }
  ]

  for (const testCase of cases) {
    const previousStepKey = findPreviousStepKey(testCase.currentStepKey, testCase.availableStepsKeys);
    console.log("previousStepKey", previousStepKey);
    console.assert(previousStepKey === testCase.expectedPreviousStepKey, `expected ${testCase.expectedPreviousStepKey} but got ${previousStepKey}`);
  }
}

test();




export default function useCurrentMissionNavigation() {
  const {
    missionContext,
    setMissionCurrentStep,
  } = useCurrentMission();

  const currentActiveStepIndex = verticalSteps.findIndex((step) => {
    return step.allowedStepKeys.includes(missionContext?.currentStep || 'search');
  });

  return {
    currentActiveStepIndex,
    canGoBack: () => {
      if (!missionContext) {
        return false;
      }

      const currentStepKey = missionContext.currentStep;

      const previousStepKey = findPreviousStepKey(currentStepKey, Object.keys(missionContext.steps) as MissionStepKeys[]);

      return previousStepKey !== null;
    },

    onClickVerticalStepIndex: (index: number) => {
      console.log("onClickVerticalStepIndex", index);
      if (index >= verticalSteps.length) {
        return;
      }
      const verticalStep = verticalSteps[index];
      let nextStepKey = verticalStep.allowedStepKeys[0];
      for (let i = 0; i < verticalStep.allowedStepKeys.length; i++) {
        if (missionContext?.steps[verticalStep.allowedStepKeys[i]]) {
          nextStepKey = verticalStep.allowedStepKeys[i];
        }
      }
      console.log("onClickVerticalStepIndex nextStepKey", nextStepKey);
      setMissionCurrentStep(nextStepKey);
    },

    missionContextGoBack: async () => {
      if (!missionContext) {
        return;
      }

      const currentStepKey = missionContext.currentStep;
      const previousStepKey = findPreviousStepKey(currentStepKey, Object.keys(missionContext.steps) as MissionStepKeys[]);

      if (!previousStepKey) {
        return;
      }

      setMissionCurrentStep(previousStepKey);

      // return await missionContextGoToStep(previousStepKey);
    },
  }
}